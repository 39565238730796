import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import ActionButtons from '../../components/SharedComponents/ActionButtons/BasicActionButtons';
import BasicCheckBox from '../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import './LocationComponent.scss';
import { validateCharNumHashSpaceOnly, validateCharNumCommaDotWithSpace, validateCharctersOnly, validateAllPhone, formatZipCode, checkZipCode, validateNumbersOnly, validateCharctersSpaceOnly, formatPhoneNumbers } from '../Helper';
import BasicTextArea from '../../components/SharedComponents/BasicTextArea/BasicTextArea';
import messages from '../Messages.json';
import sizeLimits from '../SizeLimits.json';
import { withUserContext } from '../../../contexts/UserContext';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import { Popover } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PhoneComponent from '../DefaultPhoneComponent/PhoneComponent';
import SearchSelectField from '../../components/SharedComponents/SearchSelectField';

class LocationComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addressTypes: [],
            isIntlChecked: false,
            selectedCountryValue: 0,
            selectedStateValue: '',
            intlCountryStatus: false,
            intlStateStatus: false,
            address: props?.address,
            locationConfig: this.props?.locationConfig,
            phoneTypes: [],
            initialObjectConfig: {
                object_id: null,
                object_type: null,
                value: null,
                is_primary: 0,
                is_delete: 0,
                ext: null
            },
            postInitiated: false
        }
    }

    componentDidMount() {
        this.getaddressTypes();
        this.getCountryList();
        this.getStateList();
        this.getPhoneTypes();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isEditing !== state.isEditing) {
            return {
                isEditing: props.isEditing
            }
        }
        return null
    }

    getaddressTypes = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=Address`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.lookup_data_id, label: item.lookup_value }));
                this.setState({ addressTypes: formattedList },
                    () => {
                        // if (this.props.list[this.props.addressConfig?.addressTypeId] === null) {
                        //     this.props.editCallback('address_type_id', this.state.addressTypes[0].value, this.props.list, 'address_list', 'Check');
                        //     this.props.editCallback('address_type', this.state.addressTypes[0].label, this.props.list, 'address_list', 'Check');
                        // }
                    });
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err)
                })
    }

    getPhoneTypes = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=Phone`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.lookup_value, label: item.lookup_value }));
                this.setState({ phoneTypes: formattedList },
                    () => {
                        // if (this.props.list[this.props.addressConfig?.addressTypeId] === null) {
                        //     this.props.editCallback('address_type_id', this.state.addressTypes[0].value, this.props.list, 'address_list', 'Check');
                        //     this.props.editCallback('address_type', this.state.addressTypes[0].label, this.props.list, 'address_list', 'Check');
                        // }
                    });
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err)
                })
    }

    getCountryList = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=Country`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.lookup_value, text: item.lookup_value }));
                let defaultCountry = formattedList.filter(item => {
                    return item.label === "United States" || item.label === "USA" || item.label === "US"
                })

                this.setState({
                    countryList: formattedList, selectedCountryValue: defaultCountry[0]?.value
                });
                this.setState((prevState) => ({
                    locationConfig: {
                        ...prevState?.locationConfig,
                        ["country"]: this.state.locationConfig?.country
                    },
                    countryList: formattedList
                }))

            },
                (err) => {
                    console.log("Error in fetching Address Types:", err)
                })
    }
    getStateList = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=State`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.lookup_value?.includes("-") ? item.lookup_value?.split("-")?.[0] : item.lookup_value, text: item.lookup_value?.includes("-") ? item.lookup_value?.split("-")?.[0] : item.lookup_value }));
                this.setState({ stateList: formattedList });
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err)
                })
    }

    onChangeCallback = (field, value) => {
        let locationConfig = { ...this.state.locationConfig }
        if (field === "is_primary") {
            locationConfig[field] = value ? 1 : 0
            this.setState({ locationConfig: locationConfig })
        }
        else {
            locationConfig[field] = value
            this.setState({ locationConfig: locationConfig }, () => {
                if (field == 'state' && (this.state.locationConfig?.state?.value)) {
                    let defaultCountry = this.state.countryList.find(item => {
                        return (item.text === "United States" || item.text === "USA" || item.text === "US")
                    })
                    this.setState((prevState) => ({
                        locationConfig: {
                            ...prevState?.locationConfig,
                            ['country']: defaultCountry,
                        }
                    }))
                } else if (field == 'country') {
                    this.setState((prevState) => ({
                        locationConfig: {
                            ...prevState?.locationConfig,
                            ['state']: null,
                        }
                    }))
                }
            })
        }

        // field === "country" ? this.setState({ selectedCountryValue: value }) : null;
    }

    onClick = () => {
        this.setState({ open: true })
    }
    handleClose = () => {
        let locationConfig = { ...this.state?.locationConfig }
        locationConfig = { ...locationConfig, country: locationConfig?.country?.value };
        locationConfig?.country === 'United States' || locationConfig?.country === 'USA' || locationConfig?.country === 'US' ? locationConfig?.state ? locationConfig = { ...locationConfig, state: locationConfig?.state?.value } : null : null
        this.props?.onLocationSave(locationConfig)
        this.setState({ locationConfig: locationConfig }, () => {
            this.props?.popOverClose()
        })
    }

    saveLocation = () => {
        this.setState({ postInitiated: true })
        let locationConfig = { ...this.state?.locationConfig }
        locationConfig = { ...locationConfig, country: locationConfig?.country?.value };
        locationConfig?.country === 'United States' || locationConfig?.country === 'USA' || locationConfig?.country === 'US' ? locationConfig?.state ? locationConfig = { ...locationConfig, state: locationConfig?.state?.value } : null : null
        if (locationConfig?.actual_address && locationConfig?.location_name && locationConfig?.country && (locationConfig?.zip?.length > 0 ? (locationConfig?.country === 'United States' || locationConfig?.country === 'USA' || locationConfig?.country === 'US' ? (locationConfig?.zip.length <= sizeLimits?.intlZipCharacterLimit && (locationConfig?.zip?.length == sizeLimits?.intlZipCharacterLimit ? checkZipCode(locationConfig?.zip) : true)) : true) : true)) {
            this.props?.onLocationSave(locationConfig)
            this.setState({ locationConfig: locationConfig, postInitiated: true }, () => {
                this.props?.popOverClose()
            })
        }
    }

    onChangeObject(list, id, key, value) {
        let objectList = [...this.state?.locationConfig?.[list]];
        let objectIndex = id ? objectList?.findIndex(i => i.object_id == id) : 0;
        if (key == "is_primary") {
            objectList?.map((i) => (i.is_primary = 0));
            objectList[objectIndex][key] = 1;
        } else if (key == "is_delete") {
            objectList[objectIndex][key] = 1;
        } else {
            objectList[objectIndex][key] = value;
        }
        this.handleGeneralEdit(list, objectList)
    }

    handleGeneralEdit = (field, value) => {
        let newValue = value;
        if (typeof value === "boolean") {
            newValue = value ? 1 : 0
        }
        this.setState(prevState => ({
            locationConfig: {
                ...prevState.locationConfig,
                [field]: newValue
            },
            postInitiated: false
        }))
    }

    addRemoveObject(list, value, field, index) {
        let objectList = [...this.state?.locationConfig?.[list]];
        let containsPrimary = objectList?.find(item => item.is_primary === 1 && item.is_delete === 0)
        if (!containsPrimary) {
            objectList[index].is_primary = 1
        }
        if (field === "is_delete") {
            let DeletedItem = objectList?.filter(item =>item.is_delete === 1);
            objectList = objectList?.filter(item =>item.is_delete != 1);
            objectList[index].is_delete = 1;
            DeletedItem.length >=1 ? objectList = [...objectList,...DeletedItem] :'';
            
        }
        if (field != "is_delete" && validateAllPhone(value.value)) {
            objectList = objectList?.filter((i, index) => index != 0);
            let initObjectConfig = JSON.parse(JSON.stringify(this.state.initialObjectConfig));
            objectList = [initObjectConfig, ...objectList, value]
        }
        this.handleGeneralEdit(list, objectList)
    }

    render() {
        let countryFound = this.state.countryList?.find(item => item?.value == this.state.locationConfig?.country)?.label
        return (
            <>
                {/* <MDBContainer className="AddressComponentContainer">
                <MDBCol>
                    <BasicButton 
                        text={"Add Address"}
                        icon={'plus-circle'}
                        onClick={this.onClick}
                        type="inline"
                        variant="outlined"
                    /> */}
                <Popover
                    open={this.props?.openLocationPopOver}
                    anchorEl={this.props?.setPopOver}
                    onClose={() => { this.handleClose() }}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    PaperProps={{ style: { width: '45%', height: '45%', backgroundColor: '#F2F2F2' } }}
                    className="locationPopover"
                >
                    <div className="pop-over-style">
                        <MDBRow>
                            <MDBCol md={6}>
                                <BasicTextField
                                    id={"locationName"}
                                    placeholder={"Location Name"}
                                    label={"Location Name"}
                                    value={this.state.locationConfig?.location_name}
                                    onChange={(e) => this.onChangeCallback("location_name", e.target.value)}
                                    disabled={this.props.disabled || false}
                                    isMandatory={true}
                                    showMandatory={this.state.postInitiated}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                {this.state?.locationConfig?.phone_list?.filter(i => i?.is_delete == 0)?.map((item, index) => (
                                    <PhoneComponent
                                        radioGroupName='agencyPhoneRadio'
                                        // showAsLabel={index == 0 ? false : !this.props?.isEditing}
                                        isEditing={this.props?.isEditing}
                                        objectItem={item}
                                        ext={true}
                                        fieldValid={item?.value ? !validateAllPhone(item?.value) : false}
                                        inValidInput={"Please enter valid phone"}
                                        addCallback={(obj) => this.addRemoveObject('phone_list', obj, "", index)}
                                        removeCallback={(obj) => this.addRemoveObject('phone_list', obj, 'is_delete', index)}
                                        editCallback={(id, key, value) => this.onChangeObject('phone_list', id, key, value != null && value.length > 0 ? value : null)}
                                        showAdd={index == 0 ? true : false}
                                        selectOptions={this.state?.phoneTypes}
                                        showRemove={index != 0 && this.props?.isEditing ? true : false}
                                        showRadio={index != 0 && this.props?.isEditing ? true : false}
                                        inputValidateMethod={validateAllPhone}
                                        showLabel={index == 0 ? true : false}
                                        labelNew={"Phone"}
                                        addButton={index == 0 ? true : false}
                                    />))}
                            </MDBCol>
                        </MDBRow>
                        <div className="phonesDiv">Add phone(s)-</div>
                        <MDBRow>
                            <MDBCol md={8}>
                                <BasicTextArea
                                    id={"addressLine"}
                                    rows={4}
                                    value={this.state.locationConfig?.actual_address}
                                    limit={sizeLimits.addressCharacterLimit}
                                    limitWarning={messages.exceed100CharacterWarning || ""}
                                    placeholder={"Address"}
                                    showAsLabel={this.props.disabled || false}
                                    onChange={(e) => this.onChangeCallback("actual_address", e.target.value.length > 0 ? e.target.value : null)}
                                    isMandatory={true}
                                    showMandatory={this.state.postInitiated}
                                    label={"Address"}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={6}>
                                <BasicTextField id="city"
                                    value={this.state.locationConfig?.city}
                                    onChange={(e) => this.onChangeCallback("city", e.target.value.length > 0 ? e.target.value : null)}
                                    placeholder="City"
                                    disabled={this.props.disabled || false}
                                    limit={sizeLimits.cityCharacterLimit}
                                    showAsLabel={this.props.disabled || false}
                                    isMandatory={false}
                                    inValidInput={messages.invalidInput || ""}
                                    limitWarning={messages.exceed20CharacterWarning || ""}
                                    label={"City"} />
                            </MDBCol>
                            <MDBCol md={6} className="state">
                                {
                                    (this.state.locationConfig?.country?.value === 'United States' || this.state.locationConfig?.country?.value === 'USA' || this.state.locationConfig?.country?.value === 'US' || !this.state.locationConfig?.country?.value) ?
                                        <SearchSelectField
                                            id={"state"}
                                            disabled={this.props.disabled || false}
                                            value={this.state.locationConfig?.state || []}
                                            options={this.state.stateList || []}
                                            onChange={(e, value) => this.onChangeCallback('state', value)}
                                            width={'100%'}
                                            showAsLabel={this.props.disabled || false}
                                            label={"State"}
                                        />
                                        // <SelectField
                                        //     id={"state"}
                                        //     placeHolderText={"State/Province"}
                                        //     disabled={this.props.disabled || false}
                                        //     value={this.state.locationConfig?.state}
                                        //     isMandatory={false}
                                        //     options={this.state.stateList || []}
                                        //     onChange={(e) => this.onChangeCallback("state", e.target.value)}
                                        //     showAsLabel={this.props.disabled || false}
                                        //     stateLabel={this.props.disabled ? true : false}
                                        //     label={"State"}
                                        // />
                                        :
                                        <BasicTextField id="state"
                                            limit={sizeLimits.stateCharacterLimit}
                                            disabled={this.props.disabled || false}
                                            inValidInput={messages.invalidInput || ""}
                                            limitWarning={messages.exceed20CharacterWarning || ""}
                                            placeholder={"State/Province"}
                                            value={this.state.locationConfig?.state}
                                            isMandatory={(this.props?.checkMandatory && this.props.postInitiated) || false}
                                            showMandatory={(this.props?.checkMandatory && this.props.postInitiated) || false}
                                            onChange={(e) => this.onChangeCallback("state", e.target.value.length > 0 ? e.target.value : null)}
                                            // disabled={this.props.list[addressConfig?.countryId] ? false : true}
                                            showAsLabel={this.props.disabled || false}
                                            label={"State"} />
                                }
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={4}>
                                <BasicTextField id="zipCode"
                                    disabled={this.props.disabled || false}
                                    // limit={this.state.locationConfig && (this.state.locationConfig?.country !== this.state.countryList?.find(item => item?.label == 'United States')?.value) ?
                                    //     sizeLimits.intlZipCharacterLimit : sizeLimits.usZipCharacterLimit}
                                    // limitWarning={this.state.locationConfig && (this.state.locationConfig?.country !== this.state.countryList?.find(item => item?.label == 'United States')?.value) ?
                                    //     messages.addressLimitIntl : messages.addressLimitUs}
                                    value={this.state.locationConfig?.zip}
                                    isMandatory={(this.props?.checkMandatory && this.props.postInitiated) || false}
                                    showMandatory={(this.props?.checkMandatory && this.props.postInitiated) || false}
                                    // onChange={(e) => validateNumbersOnly(e.target.value) ?
                                    //     this.onChangeCallback("zip", e.target.value.length > 0 ? e.target.value : null) : null}
                                    onChange={(e) => (this.state?.locationConfig?.country?.value == 'United States' || this.state?.locationConfig?.country?.value == 'USA' || this.state?.locationConfig?.country?.value == 'US') ? this.onChangeCallback('zip', formatZipCode(e.target.value)) :
                                        this.onChangeCallback('zip', e.target.value)}
                                    placeholder="Postal Code"
                                    showAsLabel={this.props.disabled || false}
                                    label={"Postal Code"}
                                    inValidInput={messages.invalidInput}
                                    fieldValid={this.state?.locationConfig?.zip ? ((this.state?.locationConfig?.country?.value == 'United States' || this.state?.locationConfig?.country?.value == 'USA' || this.state?.locationConfig?.country?.value == 'US') ?  (this.state?.locationConfig?.zip?.length > 9 ? !checkZipCode(this.state?.locationConfig?.zip) ? true : false : false) : false) : false} />
                            </MDBCol>
                            <MDBCol md={6} className="countryLocation">
                                {/* <SearchSelectField
                                    id={"country"}
                                    disabled={this.props.disabled || false}
                                    value={this.state?.locationConfig?.country || []}
                                    options={this.state.countryList || []}
                                    onChange={(e, value) => this.onChangeCallback('country', value)}
                                    width={'100%'}
                                    showMandatory={this.state?.postInitiated}
                                    isMandatory={true}
                                    showAsLabel={this.props.disabled || false}
                                    label={"Country"}
                                /> */}
                                <SearchSelectField
                                    id={"country"}
                                    disabled={this.props.disabled || false}
                                    value={this.state.locationConfig?.country || []}
                                    options={this.state.countryList || []}
                                    onChange={(e, value) => this.onChangeCallback('country', value)}
                                    width={'100%'}
                                    showMandatory={this.state?.postInitiated}
                                    isMandatory={true}
                                    showAsLabel={this.props.disabled || false}
                                    label={"Country"}
                                    placeholder={"Country"}
                                />
                                {/* <SelectField id={"country"}
                                    placeHolderText={"Country"}
                                    disabled={this.props.disabled || false}
                                    value={this.state.locationConfig?.country}
                                    options={this.state.countryList || []}
                                    onChange={(e) => this.onChangeCallback("country", e.target.value)}
                                    showAsLabel={this.props.disabled || false}
                                    isMandatory={true}
                                    showMandatory={this.state.postInitiated}
                                    label={"Country"}
                                /> */}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={7}></MDBCol>
                            <MDBCol md={2}>
                                <BasicButton
                                    className={"save-address"}
                                    disabled={this.props.disabled || false}
                                    variant="contained"
                                    text=" "
                                    icon={"save"}
                                    onClick={() => { this.saveLocation() }}
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                                <BasicButton
                                    variant="outlined"
                                    text=" "
                                    icon={"times"}
                                    onClick={this.handleClose}
                                />
                            </MDBCol>
                        </MDBRow>
                    </div>
                </Popover>
                {/* </MDBCol>
            </MDBContainer> */}
            </>
        );
    }
}

export default withUserContext(LocationComponent);