import { MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import React, { Component } from 'react';
import TableComponent from '../../components/SharedComponents/Table';
import { performerConfig, performersTabs } from './Config';
import './Performer.scss';
import * as Constants from '../../../constants/constants';
import CasterService from '../../../services/service';
import SearchFieldWithAddValue from '../SharedComponents/SearchFieldWithAddValue';
import NewTalent from '../../Common/NewTalent/NewTalent';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import CancelIcon from '@material-ui/icons/Cancel';
import HorizontalTabComponent from '../../Common/HorizontalTab/HorizontalTabComponent';
import ProjectDetailsTab from './ProjectDetailsTab';
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';
import ContactsAndPayments from './ContactsAndPayments';
import MessageModal from '../SharedComponents/MessageModal';
import ProfilePictureUpload from '../../Common/DefaultModalLayout/ProfilePicture/ProfilePictureUpload';
import ModalComponent from '../../Common/DefaultModalLayout/Modal/Modal';
import { talentFCNavList } from '../Talent/Config';
import { CircularProgress } from '@material-ui/core';
import { uploadProfileParams } from '../../Common/DefaultModalLayout/ProfilePicture/Config';
import CompensationTab from '../Performers/Compensation/CompensationTab';
import Credits from './Credits';
import { getFormattedDate, downloadUrl } from '../../Common/Helper';
import Modal from '../../Common/MainViewModalLayout/Modal/Modal';
import WorkActivity from "./WorkActivity";
import { Error } from '@material-ui/icons';
import Perks from '../Project/Perks';
import Summary from './Summay';
import Files from './Files';
import ReactHtmlParser from 'react-html-parser';
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent';
import AlphabetAccentChars from '../SharedComponents/AlphabetVarPopover/AlphabetVariants.json';
let backupTabIndex = null;
class Performer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            config: JSON.parse(JSON.stringify({ ...performerConfig })),
            loadingPerformer: false,
            loadingPerformerWithFilters: false,
            performerTableData: [],
            backUpPerformertableData: [],
            listForFilters: [],
            performerOptions: [],
            selectedTalent: null,
            openTalentPerformerModal: false,
            openPopOver: false,
            setPopOver: null,
            performersTabs: JSON.parse(JSON.stringify(performersTabs)),
            tabValue: 0,
            existingPerformerObj: null,
            existingPerformerId: null,
            showPerformerDetails: false,
            sortBy: "Value",
            isSortByAsc: true,
            sortCount: 0,
            isNew: false,
            performerDetailsLoading: false,
            performerDetails: null,
            age: null,
            openTalentModal: false,
            performerHeader: null,
            imgMode: "download",
            profileImg: null,
            isProfileChanged: false,
            imageType: null,
            existingTalent: false,
            projectProdCompanies: [],
            contractsLength: null,
            loanout_present: null,
            default_currency: null,
            summaryConfirm: false,
            isSummaryConfirm: false,
            openDeleteConfirmModal: false,
            isPerformerDelete: false,
            confimationMessage: null,
            downloadingPdf: false,
            openNotification: null,
            severity: null,
            pdfDownloadFailed: false,
            pdfFailMssg: null,
            monthsAge: null,
            newMinorOpenEPCModal: false,
            discardFlag: false,
            showdiscardNotification: false
        }
    }

    componentDidMount() {
        this.getPerformerList('Talent', "")
        this.getLookupValues('UNION');
        if (this.props?.production_companies) {
            let formattedList = this.props?.production_companies?.map(item => ({ value: item?.production_company, text: item?.production_company, project_production_company_id: item?.project_production_company_id }))
            var result = formattedList.reduce((unique, o) => {
                if (!unique.some(obj => obj.text === o.text && obj.value === o.value)) {
                    unique.push(o);
                }
                return unique;
            }, []);
            let configData = JSON.parse(JSON.stringify(this.state.config))
            configData.headings[4].filterOptions = result
            this.setState({ config: configData, projectProdCompanies: result });
        }
        if (this.props.navigationField !== null && this.props.obj?.project_id !== null && this.props?.tabToDisplay !== null && this.props?.navigationField !== "crew_name") {
            this.setState({ tabValue: this.props?.tabToDisplay }, () => {
                this.getPerformerTableDetails(this.props?.obj?.project_id)
                this.navigationCallback(this.props.obj, null, true);
            })
        } else {
            this.getPerformerTableDetails(this.props?.project_id)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.refreshPage?.value !== this.props?.refreshPage?.value && this.props?.refreshPage?.modal === 'ProductionCompanyFilters') {
            this.setState({ companyFilter: true }, () => {
                //this.performerListFilter(this.props?.selectedProductionCompany, false)
                this.props?.toggleRefreshPage('');
            })
        }

        if (prevProps?.selectedProductionCompany !== this.props?.selectedProductionCompany) {
            if (this.props?.selectedProductionCompany && this.props?.selectedProductionCompany?.length > 0) {
                let initialList = this.state.backUpPerformertableData.filter((item) => this.props?.selectedProductionCompany[0]?.production_company === (item.production_company) && this.props?.selectedProductionCompany[0]?.union === (item.union));
                this.getPerformerTableDetails(this.props?.project_id, "fromCompanySelection")
            } else {
                this.setState({ loadingPerformer: false }, () => { this.performerListFilter([], true, "fromCompanyUnSelection") })
            }
        }
    }

    functions = {
        handleHideTalentPopup: () => {
            this.setState({ openTalentModal: false });
        },
        handleOpenPerformerRecord: (project_id, id) => {
            this.getTalentPerformerDetails(id, true, project_id)

        }
    }

    getPerformerTableDetails = (project_id, origin = null) => {
        if (origin === "fromCompanySelection") {
            this.setState({ loadingPerformerWithFilters: true, loadingPerformer: false }, () => {
                this.performerListFilter([], true, "fromCompanySelection");
            })
        } else {
            this.setState({ loadingPerformerWithFilters: false, loadingPerformer: true })
        }
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeaturePerformerDeal?project_id=${project_id}`).then((response) => {
            if (response.data.error) {
                console.log("Getting Project Details API error");
            }
            else {
                console.log("Getting Project Details API error", response);
                response.data.forEach(element => {
                    element?.contract_list?.forEach(con => {
                        con["isClicked"] = false
                    })
                });
                let formattedList = response.data?.map((item) => {
                    return {
                        "performer_deal_id": item?.performer_deal_id,
                        "production_company_id": item?.production_company_id,
                        "project_production_company_id": item?.project_production_company_id,
                        "header_name": item?.talent_name,
                        "performer_name": item?.talent_name,
                        "performer_name_backup": item?.talent_name,
                        "selected_alias_name": item?.selected_alias_name,
                        "pka_name": item?.selected_alias_name ? item?.selected_alias_name === item?.talent_name ? null : item?.selected_alias_name?.includes('PKA') ? item?.selected_alias_name?.split('PKA')[1] : item?.selected_alias_name : item?.selected_alias_name,
                        "role_no": item?.role_no,
                        "role_name": item?.role,
                        "union": item?.union,
                        "deal_date": item?.deal_date,
                        "comments": item?.comments,
                        "performer_notes": ReactHtmlParser(item?.comments),
                        "production_company": item?.production_company,
                        "contracts": item?.contract_list?.map(contract => contract.contract),
                        "contract_list": item?.contract_list,
                        "talent_id": item?.talent_id
                    }
                })
                let contractsList = response.data?.map((performer) => performer.contract_list?.map((contract) => {
                    return ({ value: contract?.contract, text: contract?.contract })
                }))
                let listForFilters = JSON.parse(JSON.stringify(formattedList));
                listForFilters?.map((itemObj, index) => {
                    itemObj["performer_notes"] = formattedList[index]?.comments;
                });
                let formattedContractList = [].concat.apply([], contractsList);
                // var result = formattedContractList.reduce((unique, o) => {
                //     if (!unique.some(obj => obj.text === o.text && obj.value === o.value)) {
                //         unique.push(o);
                //     }
                //     return unique;
                // }, []);
                //let configData = JSON.parse(JSON.stringify(this.state.config));
                let tempFormattedList = JSON.parse(JSON.stringify(formattedList));
                // configData.headings[6].filterOptions = result;
                if (this.props?.selectedProductionCompany && this.props?.selectedProductionCompany?.length > 0) {
                    let initialList = [];
                    initialList = tempFormattedList.filter((item) => ((this.props?.selectedProductionCompany[0]?.production_company === item.production_company) && (this.props?.selectedProductionCompany[0]?.union === item?.union)));

                    this.setState({ performerTableData: initialList }, () => {
                        if (this.state.filterValues?.length > 0) {
                            this.performerListFilter(this.state.filterValues, false);
                        } else {
                            this.performerListFilter([], true, "fromCompanySelection");
                        }
                    })
                } else {
                    this.setState({ performerTableData: formattedList }, () => {
                        if (this.state.filterValues?.length > 0) {
                            this.performerListFilter(this.state.filterValues, false);
                        } else if (this.state.sortBy !== 'Value') {
                            this.handleTableColumnClick(this.state.sortBy, "preserveSorting");
                        }

                    })
                }

                this.setState({ loadingPerformerWithFilters: false, loadingPerformer: false, backUpPerformertableData: JSON.parse(JSON.stringify(formattedList)), listForFilters: listForFilters })

                console.log("API response", response);
            }
        },
            (err) => {
                this.setState({ loadingPerformer: false, loadingPerformerWithFilters: false });
                console.log("Getting Project Details API error" + err);
            })
    }

    getLookupValues(type) {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${type}`)
            .then(response => {
                if (!response || response?.data?.error) {
                    console.log("error", response?.data?.error)
                }
                else {
                    let formattedList = response?.data?.map(item => ({ value: item?.lookup_value, text: item?.lookup_value }))
                    let configData = JSON.parse(JSON.stringify(this.state.config))
                    configData.headings[3].filterOptions = formattedList
                    this.setState({ config: configData });
                }
            }, err => {
                console.log("error from lookup")
            })
    }

    getPerformerList = (objectType, searchString) => {
        this.setState({ talentsLoading: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}`)
            .then((response) => {
                if (response?.data?.error) {
                    this.setState({ talentsLoading: false })
                    console.log('error from search', repsonse?.data?.error)
                } else {
                    let formattedList = response?.data?.map(i => ({ value: i?.id, text: i?.name, talent_name: i?.talent_name, name: i?.name, name_pka: i?.name_pka }))
                    this.setState({ performerOptions: formattedList, talentsLoading: false });
                }
            },
                (err) => {
                    this.setState({ talentsLoading: false })
                    console.log("Post agency details error: " + err);
                });
    }

    handleSelectedPerformer = (value) => {
        if (value?.optionType == 'new' || value === null) {
            this.setState({ selectedTalent: value, openPopOver: true, isNew: true, setPopOver: document.getElementById('talentField'), performerHeader: value?.value })
        } else if (value == null) {
            this.setState({ selectedTalent: null })
        } else {
            this.setState({ selectedTalent: value, openTalentPerformerModal: true, existingTalent: true, performerHeader: value?.talent_name })
        }
    }

    handleClose = () => {
        this.setState({ openTalentPerformerModal: false, selectedTalent: null, existingTalent: false, isNew: false }, () => {
            this.getPerformerTableDetails(this.props?.project_id)
        })
    }

    handleClosePopOver = () => {
        this.setState({ openPopOver: false, selectedTalent: null })
    }

    validateTalent(value) {
        return true;
    }
    sendDiscardChangeNotification = (value) => {
        this.setState({ showdiscardNotification: value, discardFlag: value });
    }
    handleTabChange = (index) => {
        backupTabIndex = index;
        if (this.state.discardFlag !== index) {
            if (this.state.discardFlag === true) {
                this.sendDiscardChangeNotification(true);
            } else if (this.state.showdiscardNotification === false) {
                this.setState({ tabValue: index })
            }
        } else {
            this.setState({ tabValue: index })
        }
    }

    getAge = (birthDate) => {
        if (birthDate) {
            var today = new Date();
            let birthDateFormatted = new Date(birthDate)
            var age = today?.getFullYear() - birthDateFormatted?.getFullYear();
            var m = today?.getMonth() - birthDateFormatted?.getMonth();
            if (m < 0 || (m === 0 && today?.getDate() < birthDateFormatted?.getDate())) {
                age--;
            }
            age = (age && age > 0) ? age : (age === 0 && age > 0) ? age : 0;
            this.setState({ age: age, monthsAge: m })
        } else {
            this.setState({ age: 0 })
        }
    }

    getTabComponent = () => {
        switch (this.state?.tabValue) {
            case 0: return <ProjectDetailsTab performerDetails={this.state.performerDetails} age={this.state.age} monthsAge={this.state.monthsAge} {...this.props} />;
            case 1: return <ContactsAndPayments
                talent_name={this.state.performerDetails?.talent_name}
                performer_deal_id={this.state?.performerDetails?.performer_deal_id}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                refreshPage={this.props?.refreshPage}
                notificationComponent={this.props?.notificationComponent}
                project_id={this.props?.project_id}
                age={this.state.age}
                monthsAge={this.state.monthsAge}
                birth_date={this.state.performerDetails?.birth_date}
                newMinorOpenEPCModal={this.state.newMinorOpenEPCModal}
                getPeformerDetailsFlags={this.getPeformerDetailsFlags.bind(this)}
                getTalentPerformerDetails={this.getTalentPerformerDetails.bind(this)}
                newPerformerMinor={() => this.newPerformerMinor()}
                closePerformerMinor={() => this.closePerformerMinor()}
            />
            case 2: return <CompensationTab performer_deal_id={this.state?.performerDetails?.performer_deal_id} contract_type={this.props?.contract_type} loanout_present={this.state.loanout_present} notificationComponent={this.props?.notificationComponent} default_currency={this.state.default_currency} deal_date={this.state.performerDetails?.deal_date} project_id={this.props?.project_id} getTalentPerformerDetails={this.getTalentPerformerDetails.bind(this)} boxURL={this.props?.boxURL} age={this.state.age}
                monthsAge={this.state.monthsAge}
                birth_date={this.state.performerDetails?.birth_date}
                default_currency_value={this.state.performerDetails?.default_currency_value}
                updateDiscardChangesFlag={this.updateDiscardChangesFlag.bind(this)} showdiscardNotification={this.state.showdiscardNotification} refreshDiscardChangesFlag={this.refreshDiscardChangesFlag.bind(this)} updateDiscardChangesFlagParent={this.props?.updateDiscardChangesFlagParent} showdiscardNotificationParent={this.props?.showdiscardNotificationParent} refreshDiscardChangesFlagParent={this.props?.refreshDiscardChangesFlagParent.bind(this)} {...this.props} />
            // case 2: return <div>Compensation</div>
            case 3: return <Credits dealId={this.state?.performerDetails?.performer_deal_id} getTalentPerformerDetails={this.getTalentPerformerDetails.bind(this)} />
            case 4: return <Perks performer_deal_id={this.state?.performerDetails?.performer_deal_id}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                refreshPage={this.props?.refreshPage}
                notificationComponent={this.props?.notificationComponent}
                project_id={this.props?.project_id}
                union={this.state?.performerDetails?.union}
                getTalentPerformerDetails={this.getTalentPerformerDetails.bind(this)}
            />;
            case 5: return <WorkActivity
                performer_deal_id={this.state?.performerDetails?.performer_deal_id}
                // performer_deal_id = {39325}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                refreshPage={this.props?.refreshPage}
                notificationComponent={this.props?.notificationComponent}
                project_id={this.props?.project_id}
                getTalentPerformerDetails={this.getTalentPerformerDetails.bind(this)}
                role={this.state.performerDetails?.role}
                role_no={this.state.performerDetails?.role_no}
                updateDiscardChangesFlag={this.updateDiscardChangesFlag.bind(this)}
                updateDiscardChangesFlagParent={this.props?.updateDiscardChangesFlagParent} showdiscardNotificationParent={this.props?.showdiscardNotificationParent}
                refreshDiscardChangesFlagParent={this.props?.refreshDiscardChangesFlagParent.bind(this)} {...this.props}
                showdiscardNotification={this.state.showdiscardNotification}
                refreshDiscardChangesFlag={this.refreshDiscardChangesFlag.bind(this)}
            />;
            case 6: return <Files project_id={this.props?.project_id} performerDetails={this.state.performerDetails} />;
            case 7: return <Summary performerDetails={this.state.performerDetails} age={this.state.age}
                dealId={this.state?.performerDetails?.performer_deal_id}
                talent_name={this.state.performerDetails?.talent_name}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                refreshPage={this.props?.refreshPage}
                notificationComponent={this.props?.notificationComponent}
                project_id={this.props?.project_id}
                performer_deal_id={this.state?.performerDetails?.performer_deal_id}
                contract_type={this.props?.contract_type}
                loanout_present={this.state.loanout_present}
                default_currency={this.state.default_currency}
            />;
        }
    }

    getFooterButtonText = () => {
        switch (this.state?.tabValue) {
            case 0: return "Next: Contacts & Payments";
            case 1: return "Next: Compensation";
            case 2: return "Next: Credits";
            case 3: return "Next: Perks";
            case 4: return "Next: Work Activity";
            case 5: return "Next: Files";
            case 6: return "Next: Summary";
            case 7: return "Finish";
                break;
        }
    }

    changeTab = () => {
        if (this.state.tabValue === 7) {
            this.setState({ summaryConfirm: true })
        } else {
            let tabState = this.state.tabValue
            tabState = this.state.tabValue + 1
            this.setState({ tabValue: tabState })
        }
    }

    navigationCallback = (obj, node) => {
        if (obj?.performer_deal_id != null) {
            this.setState({ existingPerformerId: obj.performer_deal_id, existingPerformerObj: obj, showPerformerDetails: true, isNew: false, performerHeader: obj.header_name })
            this.getTalentPerformerDetails(obj.performer_deal_id);
            this.getContractDetails(obj.performer_deal_id);
        }
    }

    getPeformerDetailsFlags = (id) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=DEAL_CHILD_COUNT&performer_deal_id=${id}`)
            .then(res => {
                let response = res.data[0]
                let performerTab = [...this.state.performersTabs]
                for (let i = 0; i < performerTab?.length; i++) {
                    performerTab[i].errorFlag = performerTab[i]?.checkFlag && response?.is_complete === 0 ? response[performerTab[i]?.checkFlag] : 1
                }
                this.setState({ performersTabs: performerTab })
            },
                (err) => {
                })
    }

    editProjectDetails = () => {
        this.setState({ openTalentPerformerModal: true });
    }

    postTalentPerformerDetails = (postJson) => {
        this.setState({ isSummaryConfirm: postJson.is_delete === 1 ? false : true, summaryConfirm: false })
        postJson.immigration_status = postJson?.immigration_status?.text || null
        postJson.production_company = postJson?.production_company?.text || null
        postJson.state_inc = postJson?.state_inc?.text || null
        postJson.aliases = this.state.performerDetails?.addedAliases || []
        postJson.country_inc = postJson?.country_inc?.text || null
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/casterFeaturePerformerDeal`, postJson)
            .then(response => {
                this.setState({ isSummaryConfirm: false, isPerformerDelete: false });
                if (response.data.error) {
                    if (postJson.is_delete === '1') {
                        this.setState({ openNotification: response?.data?.message || 'Saving Changes Failed!', severity: 'error' })
                    } else {
                        this.props.notificationComponent(true, "fail")
                    }
                    this.getPerformerTableDetails(this.props?.project_id)
                }
                else {
                    if (postJson.is_delete === '1') {
                        this.setState({ openNotification: 'Performer Deleted Successfully', severity: "success" })
                        this.getPerformerTableDetails(this.props?.project_id);
                        this.setState({ showPerformerDetails: false, isNew: false })
                    } else {
                        this.props.notificationComponent(true, "success")
                        this.getTalentPerformerDetails(postJson?.performer_deal_id)
                    }
                }
            },
                (err) => {
                    this.props.notificationComponent(true, "fail")
                    this.setState({ isSummaryConfirm: false, isPerformerDelete: false },()=>{
                        this.getPerformerTableDetails(this.props?.project_id)
                    });
                })
    }

    newPerformerMinor = () => {
        this.setState({ tabValue: 1, newMinorOpenEPCModal: true })
    }

    closePerformerMinor = () => {
        this.setState({ newMinorOpenEPCModal: false })
    }

    getTalentPerformerDetails = (performer_deal_id, openModal = false, project_id = this.props?.project_id) => {
        this.getPeformerDetailsFlags(performer_deal_id)
        this.setState({ performerDetailsLoading: true })
        CasterService.getData(Constants.CasterServiceBaseUrl +
            `/casterFeaturePerformerDeal?project_id=${project_id}&performer_deal_id=${performer_deal_id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response?.data?.error || !response?.data) {
                    this.setState({ performerDetailsLoading: false })
                    console.log("Error fetching talent Details");
                }
                else {
                    let formattedList = response.data[0]
                    let loanout_present = formattedList.loanout_company ? true : false
                    formattedList.deal_date = formattedList.deal_date != "00/00/0000" ? getFormattedDate(formattedList.deal_date, true) : null
                    formattedList.ssn_type = formattedList?.ssn ? 'ssn' : 'fid'
                    formattedList.production_company = { value: formattedList.production_company, text: formattedList.production_company }
                    // formattedList.production_company = null
                    formattedList.loanout_company_state = { value: formattedList.loanout_company, text: formattedList.loanout_company }
                    formattedList.immigration_status = { id: formattedList.immigration_id, value: formattedList.immigration_status, text: formattedList.immigration_status }
                    formattedList.country_inc = { id: formattedList.country_inc_id, value: formattedList.country_inc, text: formattedList.country_inc }
                    formattedList.addedAliases = formattedList.aliases
                    formattedList.state_inc = (formattedList.country_inc?.value === 'United States' || formattedList.country_inc?.value === 'USA' || formattedList.country_inc?.value === 'US') ? { id: formattedList.state_inc_id, value: formattedList?.state_inc?.includes("-") ? formattedList?.state_inc?.split("-")?.[1] : formattedList?.state_inc, text: formattedList?.state_inc?.includes("-") ? formattedList?.state_inc?.split("-")?.[1] : formattedList?.state_inc } : formattedList.state_inc
                    CasterService.getData(Constants.CasterServiceBaseUrl +
                        `/casterFeatureTalentDetails?id=${formattedList.talent_id}`, this.props.userContext?.active_tenant?.tenant_id)
                        .then(response => {
                            if (response?.data?.error || !response?.data) {
                                console.log("Error fetching talent Setails");
                            }
                            else {
                                let loanoutOptions = response.data[0].legal_list?.map(i => ({ text: i?.loanout_company, value: i?.loanout_company }))
                                let legalList = response.data[0].legal_list
                                let alias_options = [{ value: formattedList.talent_name, label: formattedList.talent_name }]
                                if (response.data[0]?.akas.length > 0) {
                                    for (let x in response.data[0]?.akas) {
                                        alias_options.push({ value: `${formattedList.talent_name} PKA ${response.data[0]?.akas[x].aka}`, label: `${formattedList.talent_name} PKA ${response.data[0]?.akas[x].aka}` })
                                    }
                                    for (let x in response.data[0]?.akas) {
                                        alias_options.push({ value: `${response.data[0]?.akas[x].aka}`, label: `${response.data[0]?.akas[x].aka}` })
                                    }
                                }
                                let aliases = response.data[0].akas?.length > 0 ? response.data[0].akas?.map(aka => aka.aka) : []
                                formattedList.aliases = aliases.join()
                                formattedList.primary_name = response.data[0]?.primary_name === null ? formattedList?.talent_name : response.data[0]?.primary_name
                                // formattedList.selected_alias_name === null ? formattedList.selected_alias_name = "" : formattedList.selected_alias_name = formattedList.selected_alias_name
                                this.setState({ alias_options: alias_options, loanoutOptions: loanoutOptions, default_currency: formattedList?.default_currency, legalList: legalList }, () => {
                                    this.setState({ performerDetailsLoading: false, performerDetails: formattedList, loanout_present: loanout_present }, () => {
                                        this.getAge(this.state.performerDetails?.birth_date);
                                        if (openModal) {
                                            this.setState({ openTalentPerformerModal: true })
                                        }
                                    })
                                })
                            }
                        },
                            (err) => {
                                console.log("Error in fetching Talent Details:", err)
                            })
                    // this.setState({ performerDetailsLoading: false, performerDetails: formattedList, loanout_present: loanout_present }, () => {
                    //     this.getAge(this.state.performerDetails?.birth_date);
                    //     if (openModal) {
                    //         this.setState({ openTalentPerformerModal: true })
                    //     }
                    // })
                }
            },
                (err) => {
                    this.setState({ performerDetailsLoading: false })
                    console.log("Error in fetching Talent Details:", err)
                })
    }

    getContractDetails = (performer_deal_id) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeaturePerformerDealContract?performer_deal_id=${performer_deal_id}`)
            .then(response => {
                if (!response || response?.data?.error) {
                    console.log("error", response?.data?.error)
                }
                else {
                    let performersTabs = this.state.performersTabs
                    let contractsLength = response?.data?.length
                    if (contractsLength === 0) {
                        performersTabs[2].icon = <Error />
                    }
                    this.setState({ contractsLength: contractsLength, performersTabs: performersTabs });
                }
            }, err => {
                console.log("error from lookup")
            })
    }
    handleTableColumnClick = (column, origin = null) => {
        if (column === this.state.sortBy) {
            if (origin === "preserveSorting") {
                this.setState({ sortCount: this.state.sortCount }, () => {
                    this.handleTableSort(column);
                })
            } else {
                this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                    this.handleTableSort(column);
                })
            }

        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (colName) => {
        let coltype = colName === 'Deal Date' ? 'Date' : ['Role#']?.includes(colName) ? 'Number' : 'Name';
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            this.setState({
                sortBy: colName,
                performerTableData: tableSortList(coltype, this.getSortNode(colName), this.state.performerTableData, this.state.isSortByAsc)
            });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Performer": return "performer_name";
            case "Role#": return "role_no";
            case "Role Name(s)": return "role_name";
            case "Union": return "union";
            case "Deal Date": return "deal_date";
            case "Contracts": return "contract_type";
            case "Performer Notes": return "performer_notes";
        }
    }

    newTalentPerformer = (newTalent) => {
        this.setState({ selectedTalent: newTalent, openTalentPerformerModal: true, performerHeader: newTalent.headerName })
    }

    viewPerformerTable = () => {
        this.setState({ showPerformerDetails: false, isNew: false }, () => {
            this.getPerformerTableDetails(this.props?.project_id);
        })
    }

    viewPerformerData = () => {
        this.setState({ showPerformerDetails: true }, () => {
            this.getPerformerTableDetails(this.props?.project_id);
        })
    }

    generateFilePayload = (id) => {
        let objectType = "Talent"
        let fileName = `talent_${this.state.performerDetails?.talent_id}`
        let tenantName = this.props.userContext?.active_tenant?.tenant_name;
        let uploadPayload = {
            ...uploadProfileParams,
            signedURLType: this.state.imgMode,
            fileCategory: "profileimage",
            objectType: objectType,
            objectId: this.state.performerDetails?.talent_id,
            contentType: this.state.imageType || null,
            fileName,
        };
        return uploadPayload;
    }
    convertBlobToImg = (blb) => {
        const reader = new FileReader();
        reader.addEventListener('loadend', (e) => {
            const text = e.srcElement.result;
            this.setState({ profileImg: text, imgMode: "download" });
        });
        reader.readAsText(blb);
    }
    handleImgMode(mode) {
        this.setState({ imgMode: mode })
    }
    handleProfileChange(val) {
        this.setState({ isProfileChanged: val })
    }
    handleProfileImg(file) {
        this.setState({ profileImg: file })
    }
    handleImageType(val) {
        let type = val.slice(6)
        this.setState({ imageType: val })
    }
    uploadProfile = (id) => {
        let uploadPayload = this.generateFilePayload(this.props?.id);
        let file = this.state.profileImg;
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterFeaturePreSignedUrlUpload', uploadPayload, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data && response.data != []) {
                    if (this.state.imgMode === "upload") {
                        CasterService.uploadFile(response.data, file).then(response => {
                            this.setState({ imgMode: "download", isProfileChanged: false });
                        }, (err) => {
                            this.setState({ imgMode: "download", isProfileChanged: false, profileImg: null });
                            console.log("File Upload Failed:" + err);
                        });
                    }
                    else {
                        CasterService.downloadFile(response.data).then(res => {
                            // console.log("File Download Successful:" + response.data.url);
                            // let imageType = response.data.Key.split('.')
                            // imageType = imageType[1] || null
                            // this.setState({ imageType: imageType });
                            this.convertBlobToImg(res.data);

                        }, (err) => {
                            console.log("File Download Failed:" + err);
                            this.setState({ profileImg: null, imgMode: 'download' })
                        });
                    }
                }
            }, (err) => {
                console.log("Pre Signed Url Failed:" + err);
            });
    }

    performerListFilter = (filterValues, filtersNone, origin = null) => {
        var finalList = [];
        var finalResult = [];
        let filtersSelected = [];
        let colArr = {};
        let normalizedValue;
        let convertedToAscii = null;

        if (!filtersNone) {
            this.state.config?.headings?.forEach((heading, index) => {
                colArr[heading.headingLabel] = "";
            });

            filterValues.map((filter) => {
                var keyValue = filter?.toString()?.split(":");
                var name = keyValue[0]?.trim();
                var value = keyValue[1]?.trim();
                var index = -1;

                Object.keys(this.state.config?.headings).map((item) => {
                    if (this.state.config?.headings[item].headingLabel == name) {
                        let objToAdd = { [this.state.config?.headings[item].dataNode]: value }
                        colArr[name] = value;
                        filtersSelected.push(objToAdd);
                        index = item;

                    }
                });
                let tempFilterList;
                if (this.props?.selectedProductionCompany && this.props?.selectedProductionCompany?.length > 0) {
                    let initialListTemp = [];
                    initialListTemp = this.state.backUpPerformertableData.filter((item) => ((this.props?.selectedProductionCompany[0]?.production_company === item.production_company) && (this.props?.selectedProductionCompany[0]?.union === item?.union)));
                    tempFilterList = JSON.parse(JSON.stringify(initialListTemp));
                } else {
                    tempFilterList = JSON.parse(JSON.stringify(this.state?.listForFilters));
                }

                // tempFilterList.map((item)=>{
                //     delete item["performer_notes"];
                // })
                if (filtersSelected?.length > 0) {
                    let filterToNormalize = filtersSelected.filter((filterObj) => 'performer_name' in filterObj);

                    if (filterToNormalize?.length > 0) {
                        //     normalizedValue = this.convertToAscii(filterToNormalize[0]?.performer_name);
                        //     filtersSelected.map((filterObj, ind) => {
                        //         console.log("filterObj in filtersSelected", filterObj);
                        //         console.log("index in filtersSelected", ind);
                        //         if (filterObj?.performer_name) {
                        //             filterObj.performer_name = normalizedValue;
                        //         }
                        //     })
                        //     console.log("normalizedValue", normalizedValue);
                        tempFilterList?.map((item, index) => {
                            if (item["performer_name"].includes(filterToNormalize[0]?.performer_name)) {
                                //do nothing//
                                convertedToAscii = false;
                            } else {
                                item["performer_name"] = this.convertToAscii(item?.performer_name);
                                convertedToAscii = true;
                            }

                        })

                    }

                    let tempFinalResult = JSON.parse(JSON.stringify(tempFilterList))?.filter((row) =>
                        JSON.parse(JSON.stringify([...filtersSelected])).every((template) =>
                            Object.keys(template)?.every((key) => row[key]?.toString()?.toLowerCase()?.includes(template[key]?.toString()?.toLowerCase()))
                        )
                    );




                    let finalList = JSON.parse(JSON.stringify(tempFinalResult));

                    tempFinalResult?.map((item, index) => {
                        if (convertedToAscii === true) {
                            finalList[index]["performer_name"] = item?.performer_name_backup;
                        }
                        finalList[index]["performer_notes"] = ReactHtmlParser(item?.comments)
                    })



                    this.setState({ performerTableData: finalList });
                } else {

                    this.setState({ performerTableData: tempFilterList });
                }


                //tempFinalResult["performer_notes"] = tempFinalResult?.comments;


            });
            if (this.state.sortBy !== 'Value') {
                this.handleTableColumnClick(this.state.sortBy, "preserveSorting");
            }


            this.setState({ filterValues, inputValues: colArr });
        }
        else {
            let finalList;
            if (origin === "fromCompanySelection") {
                finalList = JSON.parse(JSON.stringify(this.state.performerTableData));
                JSON.parse(JSON.stringify(this.state.performerTableData))?.map((item, index) => {
                    finalList[index]["performer_notes"] = ReactHtmlParser(item?.comments);
                })
            } else if (this.props?.selectedProductionCompany && this.props?.selectedProductionCompany?.length > 0) {
                let initialListTemp1 = [];
                initialListTemp1 = this.state.backUpPerformertableData.filter((item) => ((this.props?.selectedProductionCompany[0]?.production_company === item.production_company) && (this.props?.selectedProductionCompany[0]?.union === item?.union)));
                finalList = JSON.parse(JSON.stringify(initialListTemp1));
                JSON.parse(JSON.stringify(initialListTemp1))?.map((item, index) => {
                    finalList[index]["performer_notes"] = ReactHtmlParser(item?.comments)
                })
            } else if (origin === "fromCompanyUnSelection") {
                finalList = JSON.parse(JSON.stringify(this.state.backUpPerformertableData));
                JSON.parse(JSON.stringify(this.state.backUpPerformertableData))?.map((item, index) => {
                    finalList[index]["performer_notes"] = ReactHtmlParser(item?.comments);
                })
            } else {
                finalList = JSON.parse(JSON.stringify(this.state.listForFilters));
                JSON.parse(JSON.stringify(this.state.listForFilters))?.map((item, index) => {
                    finalList[index]["performer_notes"] = ReactHtmlParser(item?.comments)
                })
            }

            this.setState({ performerTableData: finalList, filterValues, inputValues: colArr });
        }
    }

    removeProfileImg = () => {
        this.setState({ imgMode: "" }, () => {
            let payload = this.generateFilePayload(this.props?.id);
            let file = this.state.profileImg;
            CasterService.deleteDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureDeleteFiles', payload, this.props.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    if (response.data.error) {
                        // this.notificationComponent(true, "fail");
                        console.log("Profile File Removal Failed:" + err);
                    }
                    else {
                        // this.notificationComponent(true, "remove");
                        this.uploadProfile();
                    }

                }, (err) => {
                    // this.notificationComponent(true, "fail");
                    console.log("Pre Signed Url Failed:" + err);
                });
        })
    }

    pdfDownload = (performerObj, performer_deal_contract_id, node, contractIndex) => {
        let contract_info = performerObj?.contract_list?.find((contract) => contract.performer_deal_contract_id === performer_deal_contract_id)
        let postJSON = {
            payload: "casterFeaturePostTemplateToAdobe",
            encryptedString: btoa(JSON.stringify({
                performer_deal_contract_id: performer_deal_contract_id,
                template_name: contract_info.template_name,
                filename: contract_info.file_name + '.pdf',
                save_as_draft: 0,
                url: this.props?.boxURL,
                project_id: this.props?.project_id,
                preview: 1,
                contract_status: contract_info?.contract_status
            })),
            encryptionType: 1
        }
        let performerTableData = this.state.performerTableData
        performerTableData.forEach(performer => {
            performer.contract_list.forEach(contract => {
                if (contract.performer_deal_contract_id === performer_deal_contract_id) {
                    contract.isClicked = true
                }
            })
        })
        this.setState({ downloadFile: true, performerTableData: performerTableData })
        CasterService.getDataWS(postJSON).then(
            (webSocketConn) =>
                (webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data);
                    if (response?.statusCode == 200 && response?.body != "Uploaded file not found." && response?.body != "Upload folder not found." && response?.body != "Document path not found.") {
                        const filename = contract_info.file_name + '.pdf'
                        let decodedFile = decodeURIComponent(filename)
                        downloadUrl(response?.body, decodedFile);
                        let performerTableData = this.state.performerTableData
                        performerTableData.forEach(performer => {
                            performer.contract_list.forEach(contract => {
                                if (contract.performer_deal_contract_id === performer_deal_contract_id) {
                                    contract.isClicked = false
                                }
                            })
                        })
                        this.setState({ downloadFile: false, performerTableData: performerTableData })
                    } else if (response?.body === "Uploaded file not found." || response?.body === "Upload folder not found." || response?.body === "Document path not found.") {
                        this.setState({ pdfDownloadFailed: true, pdfFailMssg: response?.body })
                        let performerTableData = this.state.performerTableData
                        performerTableData.forEach(performer => {
                            performer.contract_list.forEach(contract => {
                                if (contract.performer_deal_contract_id === performer_deal_contract_id) {
                                    contract.isClicked = false
                                }
                            })
                        })
                        this.setState({ downloadFile: false, performerTableData: performerTableData })
                    } else {
                        console.log("Download Failed");
                        this.setState({ downloadFile: false })
                    }
                })
        );
    }

    onSummaryConfirm = () => {
        let postJson = { ...this.state.performerDetails }
        postJson.is_complete = 1
        this.postTalentPerformerDetails(postJson)
    }
    updateDiscardChangesFlag = (value) => {
        this.setState({ discardFlag: value });
    }
    refreshDiscardChangesFlag = (value) => {
        if (value === "confirm") {
            this.setState({ discardFlag: false, showdiscardNotification: false }, () => { this.handleTabChange(backupTabIndex) });
        } else {
            this.setState({ discardFlag: true, showdiscardNotification: false });
        }

    }
    onRefresh = () => {
        this.getTalentPerformerDetails(this.state.existingPerformerId);
        this.getContractDetails(this.state.existingPerformerId);
    }
    convertToAscii(value) {
        const stringWithoutAccents = value.normalize("NFD").replace(/[\u0300-\u036f]/g, '');
        return stringWithoutAccents.replace(/[^\u0000-\u007E]/g, character => {
            for (let key in AlphabetAccentChars) {
                if (AlphabetAccentChars[key].indexOf(character) >= 0) {
                    return key || '';
                }
            }
        });
    }
    render() {
        let id = open ? 'simple-popover' : undefined;
        let curr_state = this.state.performerDetails?.state_inc?.value
        if (curr_state?.includes('-')) {
            let splitArray = curr_state?.split("-")
            curr_state = splitArray[1]
        }
        return (
            <div className="perfomer">
                <NotificationComponent
                    open={this.state.openNotification ? true : false}
                    message={this.state.openNotification || ""}
                    severity={this.state.severity || ""}
                    handleResetNotify={() => this.setState({ openNotification: null, severity: null })} />
                <MessageModal
                    open={this.state.summaryConfirm || this.state.pdfDownloadFailed || false}
                    title={this.state.pdfDownloadFailed ? "PDF Download" : 'Confirm Summary'}
                    message={this.state.pdfDownloadFailed ? this.state.pdfFailMssg : 'Are you sure to confirm the summary for this performer?, please click OK to confirm'}
                    hideCancel={this.state.pdfDownloadFailed ? true : false}
                    primaryButtonText={"OK"}
                    secondaryButtonText={"Cancel"}
                    handleClose={() => this.setState({ summaryConfirm: false })}
                    onConfirm={(e) => { this.state.pdfDownloadFailed ? this.setState({ pdfDownloadFailed: false }) : this.onSummaryConfirm() }} />
                {!this.state.showPerformerDetails ?
                    <>
                        <div className='g-0 new-performer'>
                            <MDBRow>
                                <MDBCol md={7} className="performer-label">Add a Performer</MDBCol>
                                <MDBCol md={5} className="addPerformerDropdown">
                                    <SearchFieldWithAddValue
                                        id='talentField'
                                        ref={this.talentSelectRef}
                                        searchSelect={true}
                                        loading={this.state.talentsLoading}
                                        searchText={ev => {
                                            if (ev.target.value !== "" && ev.target.value !== null) {
                                                this.getPerformerList('Talent', ev.target.value)
                                            }
                                            else {
                                                this.setState({ talentsLoading: false })
                                            }
                                        }}
                                        value={this.state?.selectedTalent}
                                        detail_selected={this.state?.selectedTalent}
                                        options={this.state?.performerOptions || []}
                                        onChange={(e, value) => this.handleSelectedPerformer(value)}
                                        placeholder={"- Search By Alias or Company to Add"}
                                        newLabel={'Talent'}
                                        validateMethod={this.validateTalent.bind(this)}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </div>
                        <br />
                        <TableComponent
                            list={this.state.performerTableData || []}
                            className={"perfomer-table"}
                            config={this.state.config}
                            isLoadingWithFilters={this.state.loadingPerformerWithFilters}
                            isLoading={this.state.loadingPerformer}
                            hyperLinkNavigationCallback={this.navigationCallback}
                            sortCallback={this.handleTableColumnClick}
                            applyFilter={this.performerListFilter.bind(this)}
                            pdfDownload={this.pdfDownload.bind(this)}
                            stickyHeader={true}
                            filterValues={this.state.filterValues}
                            inputValues={this.state.inputValues}
                        />
                        <MDBRow>
                            <MDBCol md={11}></MDBCol>
                            <MDBCol md={1} className="performer-count">{this.state.performerTableData?.length} results</MDBCol>
                        </MDBRow>
                    </> :

                    <>
                        <MDBRow className={"blue-header"}>
                            <MDBCol md={2}></MDBCol>
                            <MDBCol md={6} className="performer-aka">
                                <>
                                    {`${this.state.performerDetails?.selected_alias_name ? this.state.performerDetails?.selected_alias_name : ''} ${this.state.age < 18 && this.state?.performerDetails?.birth_date != null ? '(K)' : ""}`}
                                </>
                            </MDBCol>
                            <MDBCol md={1} className="ellipsis-icon centerAlign">
                                {this.state.isPerformerDelete ? <CircularProgress color="inherit" size={20} /> : <MDBIcon icon='trash' onClick={() => this.setState({ openDeleteConfirmModal: true })} />}
                            </MDBCol>
                            <MDBCol md={1} className="centerAlign">
                                <BasicButton
                                    text="Profile"
                                    icon="user"
                                    variant="outlined"
                                    type="inline"
                                    onClick={() => this.setState({ openTalentModal: true })}
                                />
                            </MDBCol>
                            <MDBCol md={1} className="centerAlign">
                                <BasicButton
                                    text="Refresh"
                                    icon="sync-alt"
                                    variant="outlined"
                                    type="inline"
                                    onClick={() => this.onRefresh()}
                                />
                            </MDBCol>
                            <MDBCol md={1} className="closeIcon centerAlign"><CancelIcon style={{ fill: "white" }} onClick={() => this.viewPerformerTable()} /></MDBCol>
                        </MDBRow>
                        {this.state.performerDetailsLoading ?
                            <CircularProgress color="inherit" size={20} />
                            :
                            <div className="body">
                                <MDBRow className="performer-body">
                                    <MDBCol md={2} id="perfomer-profile">
                                        <ProfilePictureUpload
                                            isPerformer={true}
                                            headerText={'Talent'}
                                            mode={'edit'}
                                            imgMode={this.state.imgMode}
                                            profileImg={this.state.profileImg}
                                            handleImgMode={this.handleImgMode.bind(this)}
                                            handleProfileChange={this.handleProfileChange.bind(this)}
                                            handleProfileImg={this.handleProfileImg.bind(this)}
                                            uploadProfile={this.uploadProfile.bind(this)}
                                            handleImageType={this.handleImageType.bind(this)}
                                            removeProfileImg={this.removeProfileImg.bind(this)}
                                            isUpload={true}
                                            talent_id={this.props?.selectedTalent?.value}
                                            // isEditing={true}
                                            module={"TalentPerformer"}
                                            isEditing={false}
                                        />
                                    </MDBCol>
                                    <MDBCol md={6} className="role-head">
                                        {/* <span>Role: </span> {this.state.performerDetails?.role_no ? this.state.performerDetails?.role_no + " " + this.state.performerDetails?.role : this.state.performerDetails?.role} */}
                                        <MDBRow><MDBCol md={2}> <span>Role: </span></MDBCol> <MDBCol md={10}>{this.state.performerDetails?.role_no ? (this.state.performerDetails?.role_no + " " + (this.state.performerDetails?.role ? this.state.performerDetails?.role : "")) : this.state.performerDetails?.role ? this.state.performerDetails?.role : ""}</MDBCol></MDBRow>
                                        <MDBRow className="deal-date"><MDBCol md={2}><span>Deal Date: </span></MDBCol><MDBCol md={10}>{this.state.performerDetails?.deal_date != "00/00/0000" ? getFormattedDate(this.state.performerDetails?.deal_date) : ""}</MDBCol></MDBRow>
                                        <MDBRow className="loanout-company"><MDBCol md={2}><span>Loanout: </span></MDBCol><MDBCol md={10}>
                                            {this.state.performerDetails?.loanout_company ? this.state.performerDetails?.loanout_company + "| " : "Not Selected"}
                                            {curr_state && this.state.performerDetails?.loanout_company ? curr_state + ", " : ""}
                                            {this.state.performerDetails?.country_inc?.value && this.state.performerDetails?.loanout_company ? this.state.performerDetails?.country_inc?.value + "| " : ""}
                                            {this.state.performerDetails?.loanout_no && this.state.performerDetails?.loanout_company ? this.state.performerDetails?.loanout_no : ""}</MDBCol></MDBRow>
                                    </MDBCol>
                                    {/* <MDBCol md={2} className="deal-date"><span>Deal Date: </span> {this.state.performerDetails?.deal_date != "00/00/0000" ? getFormattedDate(this.state.performerDetails?.deal_date) : ""}</MDBCol>
                                    <MDBCol md={6} className="loanout-company"><span>Loanout: </span>
                                        {this.state.performerDetails?.loanout_company ? this.state.performerDetails?.loanout_company + "| " : "Not Selected"}
                                        {curr_state && this.state.performerDetails?.loanout_company ? curr_state + ", " : ""}
                                        {this.state.performerDetails?.country_inc?.value && this.state.performerDetails?.loanout_company ? this.state.performerDetails?.country_inc?.value + "| " : ""}
                                        {this.state.performerDetails?.loanout_no && this.state.performerDetails?.loanout_company ? this.state.performerDetails?.loanout_no : ""} </MDBCol> */}
                                </MDBRow>
                                <MDBRow className='performer-tabheader'>
                                    <MDBCol md={10}>
                                        <HorizontalTabComponent
                                            tabList={this.state.performersTabs}
                                            handleTabChange={this.handleTabChange.bind(this)}
                                            selectedTab={this.state.tabValue}
                                            tabValue={this.state.tabValue}
                                        />
                                    </MDBCol>
                                    <MDBCol md={2} className='centerAlign editProject'>
                                        {this.state.tabValue == 0 ? <BasicButton
                                            text="Edit Names/Project Details"
                                            variant="contained"
                                            type="inline"
                                            onClick={() => this.editProjectDetails()}
                                        /> : null}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="tab-content">
                                    {this.getTabComponent()}
                                </MDBRow>
                                {this.state.performerDetails?.is_complete === 0 && <MDBRow className="footer">
                                    <MDBCol md={10}></MDBCol>
                                    <MDBCol md={2} className="footer-button d-flex justify-content-end">
                                        <BasicButton
                                            text={this.state.isSummaryConfirm ? <CircularProgress color="inherit" size={20} /> : this.getFooterButtonText()}
                                            type="inline"
                                            variant="contained"
                                            onClick={() => this.changeTab()}
                                        />
                                    </MDBCol>
                                </MDBRow>}
                            </div>


                        }
                    </>

                }
                {this.state.openTalentPerformerModal ?
                    <Modal
                        open={this.state.openTalentPerformerModal}
                        age={this.state.age}
                        modalName={"Talent Performer"}
                        selectedTalent={this.state.selectedTalent}
                        project_id={this.props?.project_id}
                        handleClose={() => this.handleClose()}
                        primaryButtonText={"Create"}
                        isNew={this.state.isNew}
                        headerText={(this.state?.isNew || this.state?.existingTalent) ? `New Performer Record | ${this.state?.performerHeader}` : "Edit Names/Project Details"}
                        mode={"Both"}
                        headerName={this.state.performerHeader}
                        getTalentPerformerDetails={this.getTalentPerformerDetails.bind(this)}
                        getPerformerTableDetails={this.getPerformerTableDetails.bind(this)}
                        viewPerformerData={() => this.viewPerformerData()}
                        performerDetails={this.state.performerDetails}
                        existingTalent={this.state.existingTalent}
                        performer_deal_id={this.state?.performerDetails?.performer_deal_id}
                        loanoutOptions={this.state.loanoutOptions}
                        legalList={this.state.legalList}
                        getAge={() => this.getAge()}
                        alias_options={this.state.alias_options}
                        projectProdCompanies={this.state?.projectProdCompanies}
                        newPerformerMinor={() => this.newPerformerMinor()}
                        closePerformerMinor={() => this.closePerformerMinor()}
                    /> : null
                }
                {this.state.openPopOver ?
                    <NewTalent
                        id={id}
                        module={"Performer"}
                        open={this.state.openPopOver}
                        setPopOver={this.state?.setPopOver}
                        handleClose={this.handleClosePopOver.bind(this)}
                        talentData={this.state?.selectedTalent}
                        newTalentPerformer={this.newTalentPerformer.bind(this)}
                    /> : null
                }
                {
                    this.state.openTalentModal ?
                        <ModalComponent
                            id={this.state.performerDetails?.talent_id}
                            open={this.state.openTalentModal}
                            handleClose={() => this.setState({ openTalentModal: false }
                                //  () => {
                                // this.getTalentPerformerDetails(this.state.existingPerformerId)
                                // }
                            )
                            }
                            tabList={talentFCNavList}
                            reloadDetails={this.getTalentPerformerDetails.bind(this)}
                            isOpeningFromPerformer={true}
                            headerText={"Talent"}
                            mode="edit"
                            isProfileDiv={true}
                            addButtonText={"New Talent"}
                            objectType={'TALENT'}
                            objectId={this.state?.performerDetails?.talent_id}
                            headerName={this.state?.performerDetails?.primary_name}
                            akas={this.state.performerDetails?.aliases}
                            performer_deal_id={this.state?.performerDetails?.performer_deal_id}
                            dataUrl='talentDetails'
                            navFuns={this.functions}
                            {...this.props} /> : null
                }
                <MessageModal
                    open={this.state.openDeleteConfirmModal || false}
                    title={'Delete Performer'}
                    primaryButtonIcon="trash"
                    secondaryButtonIcon="times"
                    inputFieldRequired={true}
                    onConfirmationMssgChange={(v) => this.setState({ confimationMessage: v })}
                    confimationMessage={this.state.confimationMessage}
                    message={"Are you sure you would like to delete this performer? If so, type 'YES' in below box and click 'Confirm' "}
                    primaryButtonText={"Confirm"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={() => {
                        if (this.state.confimationMessage === 'YES') {
                            let postJson = { ...this.state.performerDetails }
                            postJson.is_delete = '1'
                            this.setState({ isPerformerDelete: true, openDeleteConfirmModal: false, confimationMessage: null })
                            this.postTalentPerformerDetails(postJson)
                        }
                    }}
                    handleClose={(e) => this.setState({ openDeleteConfirmModal: false, confimationMessage: null })} />
            </div>
        );
    }
}

export default Performer;